<template>
  <!--系统融合跳转的空白页面-->
  <div></div>
</template>

<script>
  export default {
    name: "empty",
    methods: {
    },
    created() {
      //  跳转关系，，
      // 如果从融创绵阳主页过来，不带token表示没登录，那么先在精准招商系统登录后，再去融创绵阳主系统登录，登录后再跳回到招商通系统
      // 如果待了token过来，表示已经融创绵阳主系统登录了，然后判断当前招商通系统是否登录，
      let obj = window.location.hash.split('?')[1].split('&')
      let token = obj[0].split('=')[1]
      let app_id = obj[1] && obj[1].split('=')[1]
      let user_id =obj[2] && obj[2].split('=')[1]
      let show =obj[3] && obj[3].split('=')[1]

      if (token != 10000) {
        // 当前融创绵阳的值系统已经登录了
        // 因为招商通系统用的是sessionStorage，所以每次打开窗口都需要登录,就不用判断了
        const loading = this.$loading({
          lock: true,
          text: '系统登录中...',
          background: 'rgba(0, 0, 0, 0)'
        });
        this.$superHttp.post('/api/auth/unify/login', {
          system_token: token,
          scene: 'admin'
        }).then(res => {
          loading.close();
          console.log(res.data.data, '金红凯江淮塞进')
          sessionStorage.adminToken = res.data.data.token;
          sessionStorage.system_token = res.data.data.system_token;
          if (app_id == 101) {
            // 当前是从融创绵阳首页跳过来的superLogin
            this.$router.replace({path: '/AdminLogin', query: {flag: 1, show}})
            return false
          }
          if (app_id == 101) {
            // 当前是从融创绵阳首页跳过来的, 去招商通的超管后台
            this.$router.replace({path: '/AdminLogin', query: {flag: 1, show}})
            return false
          }
          if (show == 22) {
            this.$router.replace({path: '/superLogin'})
            return false
          }
          // 下面的情况是从融创绵阳的主系统跳过来的
          if (res.data.data.organ_id == 0) {
            // 当前用户没有id
            this.$router.replace({path: '/AdminLogin', query: {flag: 1}})
          } else {
            this.$http.get(this.URL.adminUrl.organizationList, {params: {page: 1}
            }).then(r => {
              if (r.data.data.length === 1) {
                sessionStorage.organizationId = r.data.data.id;
                sessionStorage.organizationName = r.data.data.name;
                sessionStorage.organizationImg = r.data.data.image
                // this.$router.replace('/');
                if (app_id == 2) {
                  // 微天使发布政策过来的，
                  this.$router.push('/addArticals')
                }
                if (app_id == 3) {
                  // 微天使发布空间过来的，
                  this.$router.push('/AddSpace')
                }
              } else {
                this.$router.replace({path: '/AdminLogin', query: {flag: 1, app_id}})
              }
            })
          }
        }).catch(() => {
        })
      }  else {
        // 没有登录融创绵阳的主系统，先调整过去登录，再回来
        // window.open('http://localhost:8080/#/login?flag=2',)
        this.$router.replace({path: '/AdminLogin', query: {flag: 10, show}})
      }

    }
  }
</script>

<style scoped>

</style>
